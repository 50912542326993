import { boot } from 'quasar/wrappers'
import axios from 'axios'
import { Notify } from "quasar";
import { useI18n } from "vue-i18n";
import { i18n } from "./i18n"




const api = axios.create({ baseURL: "/api", withCredentials: true })
// todo production ??
const logger = console.log


export default boot(({ app, router }) => {

  const t = i18n.global.t;

  // request interceptors
  api.interceptors.request.use(function(config) {
    return config;
  }, function(error) {
    return Promise.reject(error);
  });

  // add echo socket id to request
  api.interceptors.request.use((config) => {
    config.headers['X-Socket-ID'] = window.Echo.socketId();
    return config;
  })

  // response interceptor
  api.interceptors.response.use(function(response) {

    // Meta Pixel Custom Event Interception
    const header = response.headers[process.env.pixel_custom_header];
    if (header) {
      window.Pixel.customTrack(header);
    }

    return { status: true, data: response.data }
  }, function(error) {
    logger(error)
    if (error.response?.data?.message === "Unauthenticated." && error.response?.status === 401)
      return window.location = "/login";

    // todo 419 status

    // todo verify if we should keep it
    // validation Error
    if (error.response?.status === 422) {
      let errors = error.response?.data?.errors
      Object.keys(errors).forEach(key => errors[key] = errors[key][0])
      return { status: false, error: errors }
    } else if (error.response?.status === 404) {
      Notify.create({
        position: 'top-right',
        color: 'red',
        message: t("Ressource non trouvée"),
        actions: [
          { label: t('retour'), color: 'white', handler: () => { router.back() } },
          { label: t('accueil'), color: 'white', handler: () => { router.replace({ name: 'home' }) } },
        ]
      })



    }
    else if (error.code == 'ERR_CANCELED') {
      //request canceled
      return { status: false, error: error }
    }
    else if (error.response?.status === 410 ) {
      return window.location = "/login";
    }
    // error
    else {
      Notify.create({
        position: 'top-right',
        color: 'red',
        message: t("une erreur s'est produite")
      });

      return { status: false, error: error }
    }
  });

  app.config.globalProperties.$api = api
  app.config.globalProperties.$axios = axios
})

export { api }
import auth from "../middleware/auth"
import onboarding from "../middleware/onBoarding"
import isCompany from "../middleware/isCompany"
import CanUserManageCompany from "src/middleware/canManageCompany"
import hasAccountant from "src/middleware/hasAccountant"
import onboarded from "src/middleware/onBoarded"

const routes = [
  {
    path: '/',
    children: [
      {
        path: 'i',
        component: ()=> import('layouts/OnBoardingLayout.vue'),
        meta: { middleware: [auth] },
        children: [
          {
            path: ":invitationToken",
            name: "invitations",
            component: ()=>import('src/pages/Invitations.vue')
          },
        ]
      },
      {
        path: 'dashboard',
        component: () => import('layouts/MultiTenantDashboard.vue'),
        meta: { middleware: [auth, onboarded] },
        name: "tenancy",
        children: [
          {
            path: "notifications",
            component: () => import("pages/Notifications.vue"),
            name: "notifications",
          },

          {
            path: "profile",
            component: () => import("pages/Profile.vue"),
            name: "profile",
          },
          {
            path: 'companies',
            component: () => import('pages/multi-tenant/Dashboard.vue'),
            name: 'tenancy.companies',
          },
          {
            path: 'firms',
            children: [
              {
                path: '',
                name: 'tenancy.firms',
                component:()=> import('pages/multi-tenant/Dashboard.vue')
              },
              {
                path: ':firmId',
                name: 'firm',
                component: () => import('pages/multi-tenant/FirmDashboard.vue'),
                children: [
                  {
                    path: 'details',
                    name: 'firm.details',
                    component: () => import('components/multi-tenant/FirmDetails.vue')
                  },
                  {
                    path: 'companies',
                    name: 'firm.companies',
                    component: () => import('components/multi-tenant/FirmComps.vue'),
                  },
                  {
                    path: 'users',
                    name: 'firm.users',
                    component: () => import('components/multi-tenant/FirmUsers.vue'),
                  },
                ]
              }
            ]
          },
        ]
      },
      {
        path: '',
        component: () => import('pages/IndexPage.vue'),
        name: "home",
        meta: { middleware: [auth] },
      },
      {
        path: 'onboarding',
        name: "onboarding",
        component: () => import('layouts/OnBoardingLayout.vue'),
        meta: { middleware: [auth, onboarding] },
        children: [
          {
            path: '',
            component: () => import('pages/onboarding/Onboarding.vue'),
            name: 'onboarding.select-type'
          },
          {
            path: 'accountant',
            component: () => import('pages/onboarding/onboardingAccountant.vue'),
            name: 'onboarding.accountant'
          },
          {
            path: 'company',
            component: () => import('pages/onboarding/onboardingCompany.vue'),
            name: 'onboarding.company'
          },
        ]
      },
    ]
  },
  {
    path: '/:company',
    component: () => import('layouts/MainLayout.vue'),
    meta: { middleware: [auth, CanUserManageCompany] },
    children: [
      {
        path: 'storage/:id?',
        component: () => import('pages/storage/StorageIndex.vue'),
        query: { uploadWindow: false, partner_id: null },
        name: "storage",
      },
      {
        //todo this is rendering only once therefor the threads are fetched only once
        path: 'threads/:archived?/:thread_id?',
        component: () => import('pages/threads/ThreadsIndex.vue'),
        name: "threads",
        meta: { threadsMainView: true },
      },
      {
        path: 'statements',
        component: () => import('pages/statements/StatementsPage.vue'),
        name: "statements",
      },
      {
        path: 'files/:id',
        component: () => import('pages/storage/FilePage.vue'),
        name: "file",
      },
      {
        path: 'files/:id/edit',
        component: () => import('pages/storage/FileForm.vue'),
        name: "file-form",
      },
      {
        path: 'settings',
        component: () => import('pages/settings/FieldsAndTypes.vue'),
        name: "settings",
        query: { tab: 'entreprise' },
      },
      {
        path: 'my-accountant',
        component: () => import('pages/MyAccountant.vue'),
        meta: { middleware: [isCompany, hasAccountant(true)] },
        name: 'company.accountant',
      },
      {
        path: 'partners',
        component: () => import('pages/Partner.vue'),
        name: "partners",
      },
      {
        path: 'unprocessed-files',
        component: () => import('pages/storage/UnprocessedFiles.vue'),
        name: "unprocessed-files",
      }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    name: "not-found",
    component: () => import('pages/ErrorNotFound.vue')
  }
]

export default routes